.font-slidwe{
   color:#282828;
   display: flex;
   align-items: center;
   min-height: 400px;
}

.font-title{
    font-size:20px !important;
    color:white;
 }

 .cont{

    margin-top:100px;
 }

 
 .rent-drive-main{
 margin-top:-10px;
 }

 .rent-drive-main-slide{

    height:100% ;
   
 }

 .slide{
 margin-top: 0;
 }


.header-actions a {
   display: inline-block;
   text-align: center;
   padding: 10px 25px 10px 0px;
   background: #ec3323 none repeat scroll 0 0;
   color: #fff;
   text-transform: uppercase;
   font-weight: 400;
   position: relative;
   z-index: 1;
}

.site-logo{

   width: 90px;
}

.header-action {
   text-align: right;
   width:60%;
   float:right;
   margin-top:-65px;
}

/* .header-top-left:before {
    background: #ec3323 none repeat scroll 0 0;
    content: "";
    height: 68% !important;
    position: absolute;
    right: 30%;
    top: 0;
    transform: skewX(45deg);
    width: 2130px;
    z-index: -1;
} */
.hero-title-1, .hero-title-2{
   color: #282828;
   font-size: 3rem;
   font-weight: 700;
   display: block;
   line-height: 1;
}
.hero-title-2{
   color: #f00010;
}
@media only screen and (min-width: 600px) {
   .thleft{
      margin-left:-150px !important;
   }
 }
 @media only screen and (max-width: 768px) {
   .cont{
      margin-top: 120px;
   }

 }



.header-top-right {
   text-align: right;
   padding: 10px 0;
}