.car-features ul li svg {
    fill: #ec3323;
    margin-right: 5px;
}

.rent-drive-btn, .rent-drive-theme-btn {
    background: transparent;
    border: 2px solid #ec3323;
    color: #ec3323;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 1em;
    padding: 8px 15px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}

.single-booking form p select {
    border: 2px solid #f0f0ff;
    color: #111;
    height: 45px;
    padding: 5px 10px;
    transition: all .4s ease 0s;
    width: 100%;
}

.row {
    margin-bottom: 5px;
}

.row1 {
    margin-bottom: 9px;
}

.rangedate{
    text-transform: uppercase;
}

.form-switch .form-check-input {
    float: right;
}

.line{
    margin:10px;
    height: 1px;
    background-color:red;
}


.booking-right.card {
    background-color: #ffffff;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 10px #ddd;
}

.booking-right.card h4 {
  color: #333;
  margin-bottom: 0;
  font-size: 25px;
  text-align: center;
}

.car-booking-right h4 span{
    /* font-size: 16px !important; */
}

.car-booking-right .car-name h5 {
  margin-bottom: 15px;
}

.car-booking-image{
    margin-bottom: 15px;
}

.car-booking-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 2px solid #ccc;
}

.price-rent h4 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.price-rent span {
  font-size: 16px;
  margin-left: 5px;
  color: #777;
}

.kps{
    background: #e7e7e7;
}

.couponbtn{
    height:38px !important;
    padding:5.5px;
    background-color:rgb(56, 75, 56);
    font-size:14px;
    color:white;
    cursor: pointer; 
}
