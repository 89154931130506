/*================================================
01 - IMPORTED CSS
==================================================*/

@import url('https://fonts.googleapis.com/css?family=Poppins:500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i&display=swap');

/*================================================
02 - DEFAULT CSS
==================================================*/

body {
    font-size: 14px;
    line-height: 26px;
    font-style: normal;
    color: #7c8a97;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

html,
body {
    height: 100%;
}

img {
    max-width: 100%;
    height: auto
}

p {
    margin: 0
}

.no-pad-left {
    padding-left: 0
}

.no-pad-right {
    padding-right: 0
}

.no-pad-all {
    padding: 0
}

.fix {
    overflow: hidden
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

a {
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #6b739c;
    text-decoration: none;
}

a:focus {
    text-decoration: none;
    outline: medium none;
    color: inherit
}

a:hover {
    color: inherit;
    text-decoration: none;
    color: inherit
}

input:focus,
textarea:focus,
button:focus,
select:focus {
    outline: medium none
}

:-moz-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

::-moz-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

:-ms-input-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

::-webkit-input-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

:-ms-select-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

::-webkit-select-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

:-ms-textarea-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

::-webkit-textarea-placeholder {
    color: #7c8a97;
    text-transform: capitalize;
}

tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

.rent-drive-btn,
button.rent-drive-btn-theme {
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #ec3323;
    position: relative;
    margin: 1em;
    display: inline-block;
    padding: 8px 15px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.rent-drive-btn:before,
.rent-drive-btn:after,
button.rent-drive-btn-theme:before,
button.rent-drive-btn-theme:after {
    content: '';
    display: block;
    position: absolute;
    border-color: #ec3323;
    box-sizing: border-box;
    border-style: solid;
    width: 1em;
    height: 1em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.rent-drive-btn:before,
button.rent-drive-btn-theme:before {
    top: -6px;
    left: -6px;
    border-width: 2px 0 0 2px;
    z-index: 5;
}

.rent-drive-btn:after,
button.rent-drive-btn-theme:after {
    bottom: -6px;
    right: -6px;
    border-width: 0 2px 2px 0;
}

.rent-drive-btn:hover:before,
.rent-drive-btn:hover:after,
button.rent-drive-btn-theme:hover:before,
button.rent-drive-btn-theme:hover:after {
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-color: #ec3323
}

.rent-drive-btn:hover,
button.rent-drive-btn-theme:hover {
    color: #fff;
    background-color: #ec3323;
    border-color: #ec3323
}

button.rent-drive-btn-theme {
    color: #ec3323;
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #ec3323;
    position: relative;
    display: block;
    padding: 8px 15px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    margin: 0;
    width: 100%
}

button.rent-drive-btn-theme:before,
button.rent-drive-btn-theme:after {
    content: '';
    display: block;
    position: absolute;
    border-color: #ec3323;
    box-sizing: border-box;
    border-style: solid;
    width: 1em;
    height: 1em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.rent-drive-btn:before,
button.rent-drive-btn-theme:before {
    top: -6px;
    left: -6px;
    border-width: 2px 0 0 2px;
    z-index: 5;
}

.rent-drive-btn:after,
button.rent-drive-btn-theme:after {
    bottom: -6px;
    right: -6px;
    border-width: 0 2px 2px 0;
}

button.rent-drive-btn-theme:hover:before,
button.rent-drive-btn-theme:hover:after {
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-color: #ec3323
}

.rent-drive-btn:hover,
button.rent-drive-btn-theme:hover {
    color: #fff;
    background-color: #ec3323;
    border-color: #ec3323
}

.site-heading {
    margin-bottom: 30px;
    width: 60%;
    margin: 0 auto;
    text-align: center
}

.site-heading h4 {
    font-size: 20px;
    color: #ec3323;
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif;
}

.site-heading h2 {
    font-size: 2rem;
    color: #001238;
    letter-spacing: -1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}

.section_100 {
    padding: 100px 0;
}

.section_70 {
    padding: 70px 0;
}

.section_50 {
    padding: 50px 0;
}

.section_15 {
    padding: 15px 0
}

.pad-right {
    padding-right: 0
}

.section_t_100 {
    padding-top: 100px
}

.section_b_70 {
    padding-bottom: 70px
}

.section_70 {
    padding: 70px 0
}

.section_b_80 {
    padding-bottom: 80px
}


/*================================================
03 - RESPONSIVE CSS
==================================================*/

.rent-drive-responsive-menu {
    display: none;
    position: absolute;
    right: 0;
    top: 15px;
    width: 100%;
    z-index: 999999;
}

.slicknav_nav a {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #000
}

.slicknav_menu {
    background: transparent;
}

.slicknav_menutxt {
    display: none !important;
}

.slicknav_arrow {
    float: right;
    color: #ec3323
}

.slicknav_nav a:hover {
    border-radius: 0;
}

.slicknav_nav .slicknav_row:hover {
    border-radius: 0
}

.slicknav_nav a:hover,
.slicknav_nav .slicknav_row:hover {
    background: #ec3323 none repeat scroll 0 0;
    color: #fff
}

.slicknav_nav a:hover .slicknav_arrow {
    color: #fff
}

.slicknav_nav a:hover a {
    color: #fff
}

.slicknav_nav li {
    border-bottom: 1px solid #ddd;
}

.slicknav_nav li.hide-desktop {
    border-bottom: 0px solid #ddd
}

.slicknav_nav li:last-child {
    border-bottom: 0px solid #ddd
}

.slicknav_nav {
    background: #fff none repeat scroll 0 0;
    border-top: 1px solid #ec3323;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.19)
}

.slicknav_btn {
    background-color: transparent !important;
    line-height: 0;
    margin-right: 0px;
    margin-top: -18px;
    float: left
}

.slicknav_menu .slicknav_icon {
    float: none !important;
    margin: 0
}

.slicknav_icon-bar {
    height: 3px !important;
    margin: 5px 0;
    width: 30px !important;
}

.slicknav_menu .slicknav_icon-bar {
    background-color: #fff;
}

.rent-drive-header-area.stick-top.sticky .slicknav_menu .slicknav_icon-bar,
.rent-drive-header-area.home-3-page .slicknav_menu .slicknav_icon-bar {
    background-color: #fff
}


/*================================================
04 - PAGINATION CSS
==================================================*/

.pagination-box-row {
    text-align: left;
}

.pagination-box-row p {
    display: inline-block;
    font-size: 15px;
    color: #444;
    margin-right: 15px
}

.pagination {
    margin-top: 50px;
    text-align: center;
    float: none;
    display: inline-block;
}

.pagination li {
    display: inline-block;
    margin-right: 5px
}

.pagination li:last-child {
    margin-right: 0
}

.pagination li a {
    display: block;
    width: 35px;
    height: 35px;
    font-size: 15px;
    line-height: 35px;
    border-radius: 5px;
    color: #111;
    font-weight: 500;
}

.pagination li.active a,
.pagination li a:hover {
    background: #101010 none repeat scroll 0 0;
    color: #fff
}


/*================================================
05 - BREADCROMB CSS
==================================================*/

.rent-drive-breadcromb-area {
    /* background: url(img/slider-1.jpg) no-repeat fixed 0 0/cover; */
    position: relative;
    z-index: 1;
    background: #ececec;
    padding-bottom: 25px;
}

/* .rent-drive-breadcromb-area:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.72) none repeat scroll 0 0;
    z-index: -1;
} */

.breadcromb-box {
    text-align: center;
}

.breadcromb-box h3 {
    font-size: 40px;
    color: #333;
    letter-spacing: 2px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.breadcromb-box li {
    display: inline-block;
    margin: 0 2px;
    color: #222 !important;
    font-size: 16px
}

.breadcromb-box li a {
    color: #222
}

.breadcromb-box li i {
    color: #ec3323
}


/*================================================
06 - BTN TOP CSS
==================================================*/

.btntoTop:before {
    color: #fff;
    content: "ï„‚";
    display: inline-block;
    font: 20px "FontAwesome";
    vertical-align: middle;
}

.btntoTop {
    background-color: #ec3323;
    border: 2px solid #ec3323;
    border-radius: 5px;
    bottom: 70px;
    cursor: pointer;
    height: 50px;
    line-height: 47px;
    opacity: 0;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: fixed;
    right: 20px;
    text-align: center;
    -webkit-transition: opacity 0.4s ease 0s;
    transition: opacity 0.4s ease 0s;
    width: 50px;
    z-index: 99999;
}

.btntoTop.active {
    opacity: 1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.btntoTop.active:hover {
    background: #212121 none repeat scroll 0 0;
    border: 2px solid #cb2415;
    opacity: 0.8;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

.btntoTop.active.btntoTop:hover:before {
    color: #fff;
}


/*================================================
07 - HEADER TOP CSS
==================================================*/

.rent-drive-header-top-area {
    background: #ececec none repeat scroll 0 0;
    color: #eee;
    margin-bottom: 0;
}

.header-top-left {
    position: relative;
    z-index: 1;
    padding: 10px 0;
}
.logo-brand{
    display: flex;
    align-items: center;
    width: 100%;
    
}
.logo-text{
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 0;
    color: #000;
}
/*--------------------------------------------------------------
# Desktop Navigation 
--------------------------------------------------------------*/
@media (min-width: 1280px) {
    .navbar {
      padding: 0;
      position: relative;
    }
  
    .navbar ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navbar li {
      position: relative;
    }
  
    .navbar>ul>li {
      white-space: nowrap;
    }
  
    .navbar a,
    .navbar a:focus {
      display: flex;
      align-items: center;
      padding: 14px 20px;
      font-family: inherit;
      font-size: 16px;
      font-weight: 400;
      color: #020202;
      white-space: nowrap;
      transition: 0.3s;
      position: relative;
    }
  
    .navbar a svg,
    .navbar a:focus svg {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      margin-top: 5px;
    }
  
    .navbar>ul>li>a:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--color-primary);
      visibility: hidden;
      transition: all 0.3s ease-in-out 0s;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }
  
    .navbar a:hover:before,
    .navbar li:hover>a:before,
    .navbar .active:before {
      visibility: visible;
      transform: scaleX(0.7);
    }
  
    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
      color: #f00010;
    }
  
    .navbar .dropdown a:hover:before,
    .navbar .dropdown:hover>a:before,
    .navbar .dropdown .active:before {
      visibility: hidden;
    }
  
    .navbar .dropdown a:hover,
    .navbar .dropdown .active,
    .navbar .dropdown .active:focus,
    .navbar .dropdown:hover>a {
      color: #f00010;
      background: #ffffff;
    }
  
    .navbar .dropdown ul {
      display: block;
      position: absolute;
      left: 0;
      top: 100%;
      margin: 0;
      padding: 0 0 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: #fbfbfb;
      transition: 0.3s;
    }
  
    .navbar .dropdown ul li {
      min-width: 200px;
    }
  
    .navbar .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      font-weight: 400;
      color: #020202;
    }
  
    .navbar .dropdown ul a i {
      font-size: 12px;
    }
  
    .navbar .dropdown ul a:hover,
    .navbar .dropdown ul .active,
    .navbar .dropdown ul .active:hover,
    .navbar .dropdown ul li:hover>a {
      color: #f00010;
      
    }
  
    .navbar .dropdown:hover>ul {
      opacity: 1;
      visibility: visible;
    }
  
    .navbar .megamenu {
      position: static;
    }
  
    .navbar .megamenu ul {
      right: 0;
      padding: 10px;
      display: flex;
    }
  
    .navbar .megamenu ul li {
      flex: 1;
    }
  
    .navbar .megamenu ul li a,
    .navbar .megamenu ul li:hover>a {
      color: #f00010;
      background: none;
    }
  
    .navbar .megamenu ul li a:hover,
    .navbar .megamenu ul li .active,
    .navbar .megamenu ul li .active:hover {
      color: #ffffff;
    }
  
    .navbar .dropdown .dropdown ul {
      top: 0;
      left: calc(100% - 30px);
      visibility: hidden;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: 100%;
      visibility: visible;
    }
  }
  
  @media (min-width: 1280px) and (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
  @media (max-width: 1279px) {
    .navbar {
      position: fixed;
    
      left: 0;
      width: 100%;
      bottom: 0;
      transition: 0.3s;
      z-index: 9997;
    }
  
    .navbar ul {
        z-index: 999;
        padding: 30px;
        background: #fff;
        position: absolute;
        height: auto;
        right: 0;
        left: 0;
    }
  
    .navbar a,
    .navbar a:focus {
       display: flex;
      align-items: center;
      padding: 8px 0;
      font-size: 16px;
      font-weight: 500;
      color: #020202;
      white-space: nowrap;
      transition: 0.3s; 
    }
  
    .navbar a i,
    .navbar a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
  
    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
      color: #f00010;
    }
  
    .navbar .dropdown ul,
    .navbar .dropdown .dropdown ul {
      position: static;
      display: none;
      padding: 10px 0;
      margin: 10px 20px;
      transition: all 0.5s ease-in-out;
      border: 1px solid rgba(var(--color-secondary-light-rgb), 0.3);
    }
  
    .navbar .dropdown>.dropdown-active,
    .navbar .dropdown .dropdown>.dropdown-active {
      display: block;
    }
  
    .mobile-nav-toggle {
      display: block !important;
      color: var(--color-secondary);
      font-size: 28px;
      cursor: pointer;
      line-height: 0;
      transition: 0.5s;
      position: fixed;
      top: 20px;
      z-index: 9999;
      right: 20px;
    }
  
    .mobile-nav-toggle.bi-x {
      color: #ffffff;
    }
  
    .mobile-nav-active {
      overflow: hidden;
      z-index: 9995;
      position: relative;
    }
  
    .mobile-nav-active .navbar {
      left: 0;
    }
  
    .mobile-nav-active .navbar:before {
      content: "";
      position: fixed;
      inset: 0;
      background: rgba(var(--color-secondary-rgb), 0.8);
      z-index: 9996;
    }
  }
.logo-text span{
color: #ec3323;
}
/* .header-top-left:before {
    background: #ec3323 none repeat scroll 0 0;
    content: "";
    height: 120%;
    position: absolute;
    right: 37%;
    top: 0;
    -webkit-transform: skewX(45deg);
    transform: skewX(45deg);
    width: 2030px;
    z-index: -1;
} */

.header-top-right {
    text-align: right;
    padding: 10px 0;
    display: flex;
    justify-content: end;
}

.header-top-left p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    position: relative;
    text-transform: capitalize;
    top: 5px;
    margin-bottom: 0;
}

.header-top-left p i {
    width: 25px;
    text-align: center;
    height: 25px;
    line-height: 25px;
    background: #fff none repeat scroll 0 0;
    color: #ec3323;
    border-radius: 50%;
    margin: 0 2px;
}

.header-top-right>.dropdown {
    display: inline-block;
}

.header-top-right>.dropdown button {
    background: transparent;
    color: #eee;
    border: medium none;
    padding: 0;
    cursor: pointer;
}

.header-top-right>.dropdown .dropdown-menu.show {
    border-radius: 0;
    border: medium none;
    background: #020202 none repeat scroll 0 0;
    color: #fff;
    margin: 0;
    left: auto !important;
    right: 0;
    padding: 0;
    min-width: 130px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.header-top-right>.dropdown .dropdown-menu li {
    padding: 5px 10px;
    border-bottom: 1px solid #444;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer
}

.header-top-right>.dropdown .dropdown-menu li:hover {
    background: #2e2e2e none repeat scroll 0 0;
}

.header-top-right>.dropdown .dropdown-menu li img {
    margin-right: 5px
}

.header-top-right>a {
    color: #eee;
    text-transform: capitalize;
    margin-right: 13px;
    min-width: 100px;
    margin-bottom: 5px;
}


/*================================================
08 - MAIN HEADER CSS
==================================================*/

.rent-drive-main-header-area {
    padding: 15px 0;
}

.header-promo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.single-header-promo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header-promo-icon {
    margin-right: 15px;
}

.header-promo-info {
    color: #555;
    font-size:20px;
}

.header-promo-info h3 {
    font-size: 20px;
    line-height: 30px;
    color: #001238;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.header-action {
    text-align: right;
}

.header-action a {
    display: inline-block;
    text-align: center;
    padding: 10px 25px 10px 0px;
    background: #ec3323 none repeat scroll 0 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    z-index: 1;
}

.header-action a:before {
    position: absolute;
    background: #ec3323 none repeat scroll 0 0;
    content: "";
    top: 0;
    left: -25px;
    width: 35%;
    height: 100%;
    -webkit-transform: skewX(45deg);
    transform: skewX(45deg);
    z-index: -1;
}

.header-action a i {
    margin-right: 2px
}

.header-action a:hover{
    color: #fff;
}


/*================================================
09 - MAINMENU CSS
==================================================*/

.rent-drive-mainmenu-area {
    background: #020202 none repeat scroll 0 0;
    border-bottom: 3px solid #363636
}

.mainmenu ul {
    text-align: left
}

.mainmenu ul li {
    display: inline-block;
    position: relative
}

.mainmenu ul li a {
    color: #eee;
    display: block;
    padding: 20px 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500
}

.mainmenu ul li:hover>a,
.mainmenu ul li.active>a {
    color: #ec3323
}

.mainmenu ul li ul {
    position: absolute;
    top: 120%;
    left: 0;
    width: 180px;
    background: #fff none repeat scroll 0 0;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.mainmenu ul li:hover ul {
    visibility: visible;
    opacity: 1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    top: 100%
}

.mainmenu ul li ul li {
    display: block;
    border-bottom: 1px solid #eee;
}

.mainmenu ul li ul li:last-child {
    border-bottom: 0px solid #eee
}

.mainmenu ul li ul li a {
    color: #001238;
    padding: 10px 15px;
    display: block;
    font-size: 14px;
}

.mainmenu ul li ul li a:hover {
    padding-left: 20px
}

.main-search-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 13px
}

.header-cart-box #dropdownMenu1 {
    border: medium none;
    background: url(img/icon-cart.png)no-repeat scroll 0 0;
    cursor: pointer;
}

.header-cart-box #dropdownMenu1>span {
    position: absolute;
    width: 18px;
    height: 18px;
    background: #ec3323 none repeat scroll 0 0;
    font-size: 10px;
    line-height: 20px;
    color: #fff;
    border-radius: 50%;
    right: -10px;
    top: -11px;
}

.cart-icon.dropdown-toggle:after {
    visibility: hidden !important
}

.search-box form {
    position: relative;
    background: #1a1a1a none repeat scroll 0 0;
    height: 40px;
    width: 100%;
    border-radius: 20px;
}

.search-box form input {
    position: absolute;
    left: 0;
    width: 100%;
    border: medium none;
    background: transparent;
    padding: 5px 10px;
    height: 100%;
    color: #eee
}

.search-box form button {
    position: absolute;
    right: 0;
    background: transparent;
    color: #eee;
    border: medium none;
    height: 100%;
    width: 22%;
    cursor: pointer;
}

.search-box {
    width: 100%;
    margin-left: 20px;
}

.dropdown-menu.cart-dropdown {
    left: auto;
    min-width: 320px;
    right: 0;
    padding: 20px;
    border-radius: 0 !important;
    top: 80px;
    margin-top: -1px
}

.login .dropdown {
    height: 80px;
    padding: 30px 0;
}

.header-cart-box .dropdown.show .cart-dropdown {
    left: auto !important;
    right: 0px !important;
    border-radius: 0px;
    padding: 20px 20px;
    min-width: 300px;
    margin-top: 45px;
    z-index: 99;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border: 3px solid #ddd;
    -webkit-transform: translate3d(0px, 0px, 0px) !important;
    transform: translate3d(0px, 0px, 0px) !important;
}

.product-remove {
    float: right;
    font-size: 16px !important;
    color: #ec3323;
}

.cart-btn-pro-img {
    float: left;
    margin-bottom: 0;
    margin-right: 20px;
    width: 75px;
}

.cart-btn-pro-img>a {
    padding: 0 !important;
}

.cart-dropdown>ul.product_list>li {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.cart-btn-pro-cont h4,
.cart-btn-pro-cont h4 a {
    font-size: 17px;
    line-height: 30px;
    color: #001238;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
}

.cart-btn-pro-cont p {
    color: #777;
    font-size: 14px;
}

.cart-btn-pro-cont span.price {
    color: #111;
    font-weight: 500;
}

.cart-subtotal p {
    color: #444;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
}

.cart-subtotal p span {
    color: #111;
    font-weight: 500;
    font-size: 16px
}

.cart-btn {
    text-align: center;
    background: #020202 none repeat scroll 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    margin: 15px auto 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.cart-btn a {
    display: inline-block;
    text-transform: uppercase;
    padding: 7px 15px;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
}

.cart-btn:after {
    position: absolute;
    content: "";
    width: 66%;
    height: 100%;
    background: #ec3323 none repeat scroll 0 0;
    right: -36px;
    z-index: -1;
    -webkit-transform: skewX(40deg);
    transform: skewX(40deg);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.cart-btn:hover {
    background: #ec3323 none repeat scroll 0 0;
}

.cart-btn:hover:after {
    background: #020202 none repeat scroll 0 0;
}


/*================================================
10 - MAIN SLIDER CSS
==================================================*/

.rent-drive-slider-area {
    position: relative
}

.slide-item-1 {
    background: url(img/slider-1.jpg);
}

.slide-item-2 {
    background: url(img/slider-2.jpg);
}

.rent-drive-main-slide {
    background-color: #eee;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
}

.slider-content-holder {
    width: 100%;
    text-align: center
}

.slider-text {
    width: 60%;
    text-align: left;
}

.rent-drive-main-caption {
    display: table;
    height: 100%;
    width: 100%;
    
}

.rent-drive-caption-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.slider-text h2 {
    color: #fff;
    font-size: 65px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    text-transform: capitalize;
    letter-spacing: 3px;
    line-height: 1.2;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
}

.slider-text h2 span {
    color: #fff;
    font-style: italic;
    font-weight: 600
}

.slider-text p {
    color: #fff;
    font-size: 26px;
    letter-spacing: 2px;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.rent-drive-slide .owl-nav>.owl-prev {
    visibility: hidden;
    -webkit-transition: all 0.2s cubic-bezier(.83, .8, .11, .64) 0s;
    transition: all 0.2s cubic-bezier(.83, .8, .11, .64) 0s;
    opacity: 0;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.rent-drive-slide:hover .owl-nav>.owl-prev {
    visibility: visible;
    opacity: 1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    left: 30px
}

.rent-drive-slide .owl-nav>.owl-next {
    visibility: hidden;
    -webkit-transition: all 0.2s cubic-bezier(.83, .8, .11, .64) 0s;
    transition: all 0.2s cubic-bezier(.83, .8, .11, .64) 0s;
    opacity: 0;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.rent-drive-slide:hover .owl-nav>.owl-next {
    visibility: visible;
    opacity: 1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    right: 30px
}

.rent-drive-slide .owl-prev {
    left: -30px;
    position: absolute;
    top: 45%;
}

.rent-drive-slide .owl-next {
    right: -30px;
    position: absolute;
    top: 45%;
}


/*================================================
11 - FIND AREA CSS
==================================================*/

.rent-drive-find-area {
    margin-top: -50px;
    background: #ececec;
}

.find-box {
    /* background: #fff url(img/find-box-bg.png) no-repeat scroll 0 0; */
    background: #ececec;
    padding: 18px 18px 6px 18px;
    position: relative;
    z-index: 999;
    box-shadow: 3px 0 29px rgba(0, 0, 0, 0.26);
}
.find-box .form-control{
    height: 45px;
    background: #e8e7e8;
}
.find-box label{
    margin-bottom: 7px;
    font-weight: 700;
    color: #282828;
    font-size: 14px;
}
.find-text {
    margin-top: 32px;
}
body .btn-theme{
    background: #f00010;
    color: #fff;
}
body .text-theme{
    color: #f00010 !important;
}

.find-text h3 {
    color: #fff;
    font-size: 33px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    line-height: 40px;
}


.find-form p input:focus {
    border: 2px solid #ec3323
}

.find-form .nice-select {
    width: 100%;
    border: 2px solid #f0f0ff;
    height: 45px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    border-radius: 0
}

.find-form .nice-select .list {
    top: 100%;
    left: 0px;
    margin: 0px;
    min-width: 100%;
    border-radius: 0px;
}

.find-form form p {
    margin: 15px 0;
}

.datepicker {
    z-index: 9999
}

.datepicker {
    border: 2px solid #f0f0ff;
    margin-top: -5px;
}

.datepicker .cell.day.selected,
.datepicker .cell.month.selected,
.datepicker .cell.year.selected,
.datepicker .cell.decade.selected {
    background: #ca3d26 none repeat scroll 0 0;
    color: #fff;
}

.datepicker .cell.day:hover,
.datepicker .cell.month:hover,
.datepicker .cell.year:hover,
.datepicker .cell.decade:hover {
    background: #ca3d26 none repeat scroll 0 0;
    color: #fff;
}

.popover.clockpicker-popover.bottom.clockpicker-align-left {
    border: 2px solid #f0f0ff;
}

.text-primary,
.clockpicker-popover .popover-title {
    color: #ef4836;
}

.datepicker .row.header a {
    margin: 0 3px;
    cursor: pointer;
    display: block;
    text-align: center;
    width: 100%;
}

.datepicker .day {
    font-size: 14px;
    text-align: center
}

.popover-title span {
    color: #ef4836
}

.popover-title .text-primary {
    color: #111 !important
}

.clockpicker input {
    font-size: 14px;
    color: #7c8a97
}

.clockpicker input:focus {
    box-shadow: 0 0 0 rgba(0, 123, 255, 0.25);
    border: 2px solid #f0f0ff;
    border-radius: 0
}


/*================================================
12 - ABOUT AREA CSS
==================================================*/

.about-left h4 {
    font-size: 20px;
    color: #ec3323;
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif
}

.about-left h2 {
    font-size: 40px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.about-list ul {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    margin-top: 10px;
}

.about-list ul li {
    margin: 5px 0
}

.about-list ul li i {
    margin-right: 7px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 2px;
    background: #ec3323 none repeat scroll 0 0;
    color: #fff;
    line-height: 20px;
    font-size: 12px;
}

.about-signature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
}

.signature-left {
    width: 190px;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 3px solid #f0f0ff;
}

.signature-right h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #001238;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.about-right {
    margin-top: 30px;
}


/*================================================
13 - SERVICE AREA CSS
==================================================*/

.rent-drive-service-area {
    background: #ececec;
    padding-bottom: 105px
}

.service-page-area {
    padding-bottom: 70px
}

.single-service {
    padding: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    position: relative;
    margin: 5px;
    text-align: center;
}

.service-page-area .single-service {
    margin: 30px 0 0 0;
}

.service-number {
    font-size: 55px;
    position: absolute;
    top: 25px;
    right: 25px;
    width: 60px;
    height: 55px;
    font-weight: 500;
    line-height: 55px;
    color: rgba(35, 61, 92, 0.07);
    border-radius: 0 0 0 10px;
    z-index: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.service-icon {
    width: 120px;
    margin: auto;
}

.service-text {
    margin-top: 25px;
}

.service-text h3 {
    font-size: 21px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.service-text a {
    color: #001238
}

.service-text a:hover,
.service-text h3:hover {
    color: #ec3323
}

.service-slider .owl-dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -40px
}

.service-slider .owl-dots .owl-dot {
    display: inline-block;
}

.service-slider .owl-dots .owl-dot span {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    background: #020202 none repeat scroll 0 0;
    border-radius: 0;
    display: block;
    height: 12px;
    margin: 5px 3px 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 7px;
}

.service-slider .owl-dots .owl-dot.active span,
.service-slider .owl-dots .owl-dot:hover span {
    background: #ec3323 none repeat scroll 0 0;
}


/*================================================
14 - PROMO AREA CSS
==================================================*/

.rent-drive-promo-area {
    position: relative;
    background: url(img/promo_bg.jpg) no-repeat fixed 0 0/cover;
    z-index: 1;
}

.rent-drive-promo-area:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
    z-index: -1;
}

.promo-box-left {
    position: relative;
    z-index: 1;
    padding-top: 70px;
    padding-bottom: 50px;
}

.promo-box-right {
    padding: 72px 0
}

.promo-box-left:after {
    position: absolute;
    background: #ec3323 none repeat scroll 0 0;
    content: "";
    top: 0;
    right: 55%;
    width: 2030px;
    height: 100%;
    -webkit-transform: skewX(45deg);
    transform: skewX(45deg);
    z-index: -1;
}

.promo-box-left:before {
    position: absolute;
    background: #fff none repeat scroll 0 0;
    content: "";
    top: 0;
    right: 43%;
    width: 70px;
    height: 100%;
    -webkit-transform: skewX(45deg);
    transform: skewX(45deg);
    z-index: -1;
}

.promo-box-right h3 {
    font-size: 34px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 50px;
}


/*================================================
15 - HOT OFFERS AREA CSS
==================================================*/

#offerTab {
    text-align: center;
    width: 100%;
    display: block;
    border: medium none;
    margin: 30px 0
}

#offerTab li {
    display: inline-block;
    margin: 0 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border: medium none
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: medium none
}

.nav-tabs .nav-link {
    border: medium none
}

#offerTab li.nav-item a.nav-link,
#offerTab li.nav-item a.nav-link.active {
    display: inline-block;
    text-transform: uppercase;
    padding: 7px 25px;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    position: relative;
    z-index: 1;
}

#offerTab li.nav-item a.nav-link:after {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: #020202 none repeat scroll 0 0;
    z-index: -1;
    -webkit-transform: skew(40deg);
    transform: skew(40deg);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

#offerTab li.nav-item a.nav-link:hover:after {
    background: #ec3323 none repeat scroll 0 0;
    -webkit-transform: skew(-40deg);
    transform: skew(-40deg);
}

#offerTab li.nav-item a.nav-link.active:after {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: #ec3323 none repeat scroll 0 0;
    -webkit-transform: skew(-40deg);
    transform: skew(-40deg);
}

.single-offers {
    text-align: center;
    padding: 0;
    border: 0;
    margin-top: 30px;
}

.offer-image {
    width: 235px;
    margin: 0 auto;
    display: block;
}

.offer-image img {
    width: 100%;
}

.offer-text {
    margin-top: 10px;
    background: #f7f7f7;
    padding: 15px;
    min-height: 100px;
}

.offer-text h3 {
    font-size: 16px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}
.single-offers .offer-text h3 {
    min-height: 40px;
}
.offer-text h4 {
    color: #444;
    font-size: 18px;
    text-transform: capitalize;
    margin-top: 8px;
}

.offer-text h4 span {
    text-transform: capitalize;
    color: #555;
    font-size: 16px;
}

.offer-action {
    text-align: center;
    background: #ec3323 none repeat scroll 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    margin: 25px auto 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.offer-action a {
    display: inline-block;
    text-transform: uppercase;
    padding: 7px 15px;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    width: 47%;
}
.offer-action a.offer-btn-2{
    color: #363636;
}
.offer-action a.offer-btn-2:hover{
    color: #ffffff;
}
.offer-action:hover a {
    color: #ffffff;
    }
.offer-action:after {
    position: absolute;
    content: "";
    width: 66%;
    height: 100%;
    background: #ddd none repeat scroll 0 0;
    right: -36px;
    z-index: -1;
    -webkit-transform: skewX(40deg);
    transform: skewX(40deg);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.offer-action:hover {
    background: #ec3323 none repeat scroll 0 0;
}

.offer-action:hover:after {
    background: #020202 none repeat scroll 0 0;
}

.offer-text ul {
    text-align: center;
    margin-top: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.offer-text ul li i {
    margin-right: 5px;
    color: #ec3323
}
.feature-box{
    border-top: 1px solid #333;
    padding-top: 10px;
}
.single-offers .car-detail-list{
    display: block;
    text-align: left;
    padding-left: 15px;
    height: 130px;
}
.single-offers .car-detail-list li{
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 0;
    font-size: 12px;
    position: relative;
    
}
.single-offers .car-detail-list li::before{
    content: "\2713";
    position: absolute;
    left: -15px;
    top: 1px;
}



/*================================================
16 - TESTIMONIAL AREA CSS
==================================================*/

.rent-drive-testimonial-area {
    position: relative;
    background: url(img/bg-theme.svg) no-repeat fixed 0 0/cover;
    z-index: 1;
    padding-bottom: 100px
}

.rent-drive-testimonial-area:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
    z-index: -1;
}

.rent-drive-testimonial-area .site-heading h2 {
    color: #fff
}

.single-testimonial {
    background: #fff none repeat scroll 0 0;
    padding: 90px 30px 30px;
    margin-top: 30px;
}

.testimonial-text {
    position: relative;
}

.testimonial-text:after {
    position: absolute;
    content: "\f10e";
    color: #001238;
    font-size: 80px;
    font-family: Fontawesome;
    top: 34%;
    left: 35%;
    opacity: 0.1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}
.testi-after-box-left{
    position: relative;
    overflow: hidden;
}

.testimonial-meta {
    margin-top: 30px;
}

.client-image {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    float: left;
}

.client-image img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 50%;
}

.client-info h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #001238;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.client-info {
    overflow: hidden;
}

.client-info p {
    color: #ec3323;
}

.testimonial-slider .owl-dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -40px
}

.testimonial-slider .owl-dots .owl-dot {
    display: inline-block;
}

.testimonial-slider .owl-dots .owl-dot span {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    background: #fff none repeat scroll 0 0;
    border-radius: 0;
    display: block;
    height: 12px;
    margin: 5px 3px 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 7px;
}

.testimonial-slider .owl-dots .owl-dot.active span,
.testimonial-slider .owl-dots .owl-dot:hover span {
    background: #ec3323 none repeat scroll 0 0;
}


/*================================================
17 - DRIVER AREA CSS
==================================================*/

.rent-drive-driver-area {
    padding-bottom: 70px;
}

.single-driver {
    margin-top: 30px
}

.driver-img-details img {
    width: 100%
}

.driver-image {
    position: relative;
    overflow: hidden;
}

.driver-image img {
    width: 100%
}

.driver-image .hover {
    position: absolute;
    right: 0px;
    top: 20px;
    z-index: 111
}

.driver-image .hover .social-icon li+li {
    margin-top: 10px;
    display: block
}

.driver-image .hover .social-icon li a {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #ec3323;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.driver-image .hover .social-icon li a:hover {
    background-color: #001238
}

.driver-image .hover .social-icon li:nth-child(1) {
    position: absolute;
    top: 20px;
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
    -webkit-transition: transform 500ms;
    -webkit-transition: -webkit-transform 500ms;
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
}

.driver-image .hover .social-icon li:nth-child(2) {
    position: absolute;
    top: 60px;
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
    -webkit-transition: transform 500ms;
    -webkit-transition: -webkit-transform 500ms;
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
}

.driver-image .hover .social-icon li:nth-child(3) {
    position: absolute;
    top: 110px;
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
    -webkit-transition: transform 500ms;
    -webkit-transition: -webkit-transform 500ms;
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms;
}

.driver-image .hover .social-icon li:nth-child(4) {
    position: absolute;
    top: 160px;
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
    -webkit-transition: transform 500ms;
    -webkit-transition: -webkit-transform 500ms;
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(1) {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(2) {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(3) {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(4) {
    -webkit-transform: translateX(-60px);
    transform: translateX(-60px);
}

.driver-image {
    position: relative
}

.driver-image:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000 none repeat scroll 0 0;
    opacity: 0.1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)"
}

.driver-text {
    text-align: center;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .11);
    padding: 30px 15px;
}

.driver-text h3 {
    font-size: 22px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 5px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.driver-text a {
    color: #001238
}

.driver-text a h3:hover {
    color: #ec3323
}

.driver_page .pagination-box-row {
    text-align: center;
}

.load-more {
    text-align: center;
    margin-top: 20px
}

.load-more a.rent-drive-btn {
    color: #ec3323;
    margin-bottom: 0
}

.load-more a.rent-drive-btn:hover {
    color: #fff
}


/*================================================
18 - CALL AREA CSS
==================================================*/

.call-box {
    background: url(img/footer-bg.jpg)no-repeat scroll 0 0/cover;
    text-align: center;
    border-radius: 5px;
    position: relative;
    padding: 50px 0;
    z-index: 1
}

.call-box-inner {
    width: 70%;
    margin: 0 auto;
}

.call-box:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
    z-index: -1;
    border-radius: 5px
}

.call-box h2 {
    font-size: 40px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 50px;
}

.call-box h2 span {
    color: #ec3323
}

.call-box-inner p {
    color: #b6b6b6;
}

.call-number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 30px;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.call-icon {
    font-size: 50px;
    margin-right: 30px;
    color: #fff;
}

.call-text {
    text-align: left;
}

.call-text p {
    text-transform: capitalize;
    color: #e04639;
    font-size: 17px;
    margin-bottom: 5px;
}

.call-text h4 {
    color: #eee;
    font-size: 20px;
    letter-spacing: 2px
}

.call-text h4 a {
    color: #eee
}


/*================================================
19 - BLOG AREA CSS
==================================================*/

.single-blog {
    border: 3px solid #f0f0ff;
    margin-top: 30px
}

.blog-text {
    padding: 15px 20px;
}

.blog-text h3 {
    font-size: 18px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.blog-text h3 a {
    color: #001238;
}

.blog-text h3 a:hover {
    color: #ec3323
}

.blog-meta-home {
    padding-top: 15px;
    border-top: 3px solid #f0f0ff;
}

.blog-image img {
    width: 100%
}

.blog-meta-home {
    padding-top: 15px;
    border-top: 3px solid #f0f0ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-meta-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.blog-meta-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.blog-meta-right p {
    margin-left: 15px;
}

.blog-meta-right p i {
    margin-right: 5px;
    color: #ec3323
}

.rent-drive-blog-page-area {
    padding-top: 40px
}

.blog-page-left .blog-text h3 {
    font-size: 24px
}

.blog-text ul {
    margin-bottom: 20px;
}

.blog-text ul li {
    display: inline-block;
    margin-right: 10px;
}

.blog-text ul li i {
    margin-right: 5px;
    color: #ec3323;
}

.blog-text ul li a {
    color: #111;
}

.blog-text a.rent-drive-btn {
    color: #ec3323
}

.blog-text a.rent-drive-btn:hover {
    color: #fff;
}

.blog-text a.rent-drive-btn {
    color: #ec3323;
    margin: 20px 0 0 0;
}

.blog-page-right .sidebar-widget {
    margin-top: 30px;
    margin-bottom: 0
}

.blog-page-right .recent-text h4 {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 1px;
}


/*================================================
20 - FOOTER AREA CSS
==================================================*/

.footer-top-area {
    padding: 50px 0;
    background: url(img/footer-bg.jpg)no-repeat scroll 0 0/cover;
    position: relative;
    z-index: 1;
}

.footer-top-area:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.90);
    z-index: -1;
}

.single-footer {
    clear: both;
    color: #aaaeb1
}

.quick-links {
    float: left;
    width: 45%;
}

.footer-logo {
    margin-bottom: 15px;
}

.single-footer h3,
.footer-address h3 {
    font-size: 17px;
    color: #eee;
    letter-spacing: 1px;
    margin-bottom: 30px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    position: relative;
}

.single-footer>h3:after,
.footer-address>h3:after {
    position: absolute;
    content: "";
    bottom: -13px;
    width: 18%;
    height: 2px;
    background: #ec3323;
    left: 0;
}

.quick_links {
    overflow: hidden
}

.quick-links li a {
    color: #aaaeb1;
}

.quick-links li a:hover {
    color: #fff;
}


.newsletter_box {
    margin-top: 30px
}

.newsletter_box form {
    width: 230px;
    height: 40px;
    background: #222;
    position: relative;
    border-radius: 5px
}

.newsletter_box form input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #272727;
    height: 100%;
    border: medium none;
    padding: 5px 10px;
    color: #eee;
    border-radius: 5px
}

.newsletter_box form button {
    position: absolute;
    border: medium none;
    background: transparent;
    right: 10px;
    height: 100%;
    color: #ec3323;
    cursor: pointer;
}

.footer-post-image {
    width: 70px;
    float: left;
    margin-right: 10px;
}

.footer-post-image img {
    width: 100%;
    height: 100%;
    border-radius: 5px
}

.footer-post-text {
    overflow: hidden;
}

.single-footer-post {
    clear: both;
    margin-bottom: 15px;
    overflow: hidden;
}

.footer-post-text h3 {
    color: #eee;
    font-size: 14px;
    margin-bottom: 5px;
    text-transform: capitalize;
    line-height: 23px;
    font-weight: normal;
}

.footer-post-text h3 a {
    color: #aaaeb1
}

.footer-post-text h3 a:hover {
    color: #eee
}

.footer-post-text p {
    color: #e97369;
    font-style: italic;
    font-size: 13px;
}

.footer-bottom-area {
    padding: 20px 0;
    background: #020202;
    border-top: 1px solid #333;
}

.copyright p {
    color: #aaa;
    font-size: 14px;
    text-transform: capitalize;
}

.copyright p i {
    color: #ec3323;
    margin: 0 2px;
}

.copyright p a {
    color: #aaa
}

.footer-social ul {
    text-align: right;
}

.footer-social ul li {
    display: inline-block;
    margin-left: 10px;
}

.footer-social ul li a {
    display: block;
    color: #eee;
    font-size: 14px;
}


/*================================================
21 - ABOUT PAGE CSS
==================================================*/

.about-page-left h4 {
    font-size: 20px;
    color: #ec3323;
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif;
}

.about-page-left h3 {
    font-size: 36px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 45px;
}

.about-page-left p span {
    color: #111;
    text-transform: capitalize;
}

.about-page-left p {
    margin: 0 0 10px
}

.about-page-call {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.page-call-icon {
    font-size: 50px;
    margin-right: 30px;
    color: #ec3323;
}

.call-info p {
    text-transform: capitalize;
    color: #ec3323;
    font-size: 17px;
    margin-bottom: 5px;
}

.call-info h4 {
    color: #001238;
    font-size: 20px;
    letter-spacing: 2px;
}

.call-info h4 a {
    color: #001238;
}

.about-promo-text {
    text-align: center;
    width: 55%;
    margin: 0 auto;
}

.rent-drive-about-promo {
    padding-bottom: 50px;
    background: #fbfbfd none repeat scroll 0 0
}

.about-promo-text h3 {
    font-size: 36px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 45px;
}

.about-promo-text h3 span {
    color: #ec3323
}

.about-promo-image {
    margin-top: 30px;
}


/*================================================
22 - SERVICE DETAILS CSS
==================================================*/


.sidebar-widget {
    margin-bottom: 30px
}
.sidebar-widget .box{
    background: #f4f4f5 none repeat scroll 0 0;
    padding: 30px;
}
.filter-sidebar{
    position: relative;
}
.mobile-sticky {
    display: none;
}
.filter-sidebar.mobile-sticky {
    display: block;
}
.filter-d{
    display: block;
}
@media screen and (max-width: 992px) {
    .rent-drive-find-area{
        margin-top: -30px;
    }
    .rent-drive-find-area .cont {
        padding-top: 80px;
    }
 .filter-d{
    display: none;
 }
    .mobile-sticky {
        position: fixed;
        left: 0;
        top: 0px;
        background: #fff;
        right: 0;
        z-index: 99;
        max-height: 100vh;
        padding: 40px 0px;
        overflow-y: auto;
    }
    .open-close-sidebar{
        position: sticky;
        top: 10px;
        background: #fff;
        right: 0;
        left: 0;
        display: block;
        width: 100%;
        z-index: 999;
        padding: 7px;
        color: #f00010;
    }
    .open-close-sidebar.fixedTop {
        position: fixed;
    }
    .sidebar-widget .box{
        padding: 10px;
    }
}
.sidebar-widget:last-child {
    margin-bottom: 0
}

.service-menu {
    padding: 15px;
    background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
    margin-bottom: 5px;
}

.service-menu li:last-child {
    margin-bottom: 0
}

.service-menu li a {
    display: block;
    padding: 10px 20px;
    background: #fff none repeat scroll 0 0;
    color: #555 !important;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
}
.service-menu li a:hover{
    cursor: pointer;
}

.service-menu li a span {
    margin-left: 5px
}

.service-menu li.active a,
.service-menu li a:hover {
    color: #ec3323;
    padding-left: 25px
}

.brochures {
    text-align: center;
    background: #020202 none repeat scroll 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    margin: 25px auto 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.brochures:after {
    position: absolute;
    content: "";
    width: 62%;
    height: 100%;
    background: #ec3323 none repeat scroll 0 0;
    right: -36px;
    z-index: -1;
    -webkit-transform: skewX(40deg);
    transform: skewX(40deg);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.brochures a {
    display: inline-block;
    text-transform: uppercase;
    padding: 15px;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    width: 47%;
}

.brochures a i {
    margin-right: 5px
}

.brochures:hover {
    background: #ec3323 none repeat scroll 0 0;
}

.brochures:hover:after {
    background: #020202 none repeat scroll 0 0;
}

.service-page-banner {
    background: url(img/find-box-bg.png) no-repeat scroll 0 0/cover;
    padding: 30px;
    text-align: center;
    color: #fff;
}

.service-page-banner>h3 {
    font-size: 28px !important;
    color: #fff !important;
    letter-spacing: 1px !important;
    margin-bottom: 15px !important;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    line-height: 40px !important;
}

.service-page-banner a {
    margin-bottom: 0;
    border-color: #fff;
    margin-top: 20px
}

.service-page-banner a:before,
.service-page-banner a:after {
    content: '';
    display: block;
    position: absolute;
    border-color: #fff;
    box-sizing: border-box;
    border-style: solid;
    width: 1em;
    height: 1em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.service-page-banner a:hover {
    color: #ec3323;
    background-color: #fff;
    border-color: #fff;
}

.service-page-banner a:hover:before,
.service-page-banner a:hover:after {
    border-color: #fff;
}

.service-details-right h3,
.sidebar-widget h3 {
    font-size: 30px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 45px;
}

.service-details-right p {
    margin: 0 0 10px
}

.pad-right-sm {
    padding-right: 7px
}

.pad-left-sm {
    padding-left: 7px
}

.service-details-image {
    margin-top: 10px
}

.service-details-list ul {
    width: 50%;
    float: left;
    margin-top: 20px;
}

.service-details-list li {
    margin: 5px 0;
}

.service-details-list li i {
    color: #ec3323;
    margin-right: 5px;
}

.service-accordion {
    margin-top: 20px
}

.service-accordion h3,
.sidebar-widget h3 {
    font-size: 16px;
    text-transform: uppercase;
}

.Tags-catagory {
    padding: 15px;
    background: #f4f4f5 none repeat scroll 0 0;
}

.Tags-catagory li {
    display: inline-block;
    border: medium none !important;
    padding: 0 !important;
    margin: 2px 0;
}

.Tags-catagory a {
    color: #001238;
    font-weight: 500;
    text-transform: capitalize;
    display: inline-block;
    padding: 5px 15px;
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
}

.Tags-catagory a:hover {
    color: #ec3323
}

.single_faq_accordian {
    margin-top: 10px;
}

.faq_accordian_header>a {
    display: block;
    text-align: left;
    padding: 15px 15px 15px 85px;
    background: #020202 none repeat scroll 0 0;
    font-size: 15px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    border: 0px solid rgba(0, 0, 0, 0.08);
    border-radius: 0;
    text-decoration: none;
    position: relative;
    overflow: hidden;
}

.faq_accordian_header>a:hover,
.faq_accordian_header>a:focus {
    background: #020202 none repeat scroll 0 0;
    color: #fff;
    outline: medium none
}

.faq_accordian_header>a:after {
    position: absolute;
    content: "\f056";
    font-family: Fontawesome;
    left: 30px;
    color: #fff;
    font-size: 18px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.faq_accordian_header>a.collapsed:after {
    position: absolute;
    content: "\f055";
    font-family: Fontawesome;
    left: 30px;
    color: #fff;
    font-size: 18px;
}

.faq_accordian_header>a:before {
    position: absolute;
    content: "";
    top: 0;
    width: 13%;
    height: 101%;
    left: -26px;
    background: #ec3323;
    -webkit-transform: skew(-30deg);
    transform: skew(-30deg);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.faq_accordian_header>a.collapsed:before {
    position: absolute;
    content: "";
    top: 0;
    width: 13%;
    height: 101%;
    left: -26px;
    background: #ec3323;
    -webkit-transform: skew(30deg);
    transform: skew(30deg);
}

.faq_accordian_body {
    padding: 10px;
}


/*================================================
23 - CAR LISTING CSS
==================================================*/

.sidebar-widget form p input {
    width: 100%;
    border: 2px solid #fff;
    padding: 5px 10px;
    height: 45px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.sidebar-widget form p .nice-select {
    width: 100%;
    border: 2px solid #fff;
    height: 45px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    border-radius: 0;
    margin-bottom: 10px
}

.sidebar-widget form p input:focus {
    border: 2px solid #ec3323;
}

.sidebar-widget form {
    background: #f4f4f5 none repeat scroll 0 0;
    padding: 15px;
}

.sidebar-widget form p {
    margin-bottom: 10px
}

.sidebar-widget form p:last-child {
    margin-bottom: 0
}

.sidebar-widget form p .nice-select.open .list {
    width: 100%
}

.sidebar-widget form p button {
    margin-top: 20px;
    background: #fff none repeat scroll 0 0
}

.property-page-heading {
    border: 0px solid #f0f0ff;
    padding: 7px 15px;
    background: #f4f4f5 none repeat scroll 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.propertu-page-shortby {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.propertu-page-shortby .nice-select {
    background-color: #f4f4f5;
    border-radius: 0;
    border: solid 0px #e8e8e8;
    width: 200px
}

.propertu-page-head {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.propertu-page-head ul li {
    display: inline-block;
    margin-right: 10px;
}

.propertu-page-head ul li.active a {
    color: #111;
}

.paging_status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.propertu-page-head ul li a:hover,
.paging_status p {
    color: #111;
}

.propertu-page-shortby label {
    margin-top: 5px;
    margin-bottom: 0;
    margin-right: 10px;
    color: #111;
    font-size: 14px;
}

.propertu-page-shortby label i {
    margin-right: 5px;
}


/*================================================
24 - CAR BOOKING CSS
==================================================*/

.rent-drive-car-booking {
    background: #fbfbfb none repeat scroll 0 0
}

.rental-tag {
    display: inline-block;
    padding: 5px 15px;
    line-height: 20px;
    text-transform: uppercase;
    background: #ec3323;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border-radius: 3px;
    margin-bottom: 5px;
}

.car-booking-right h3 {
    font-size: 30px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 45px;
}

.car-booking-right h4 {
    color: #020202;
    font-size: 18px;
    text-transform: capitalize;
    margin-top: 0px;
    display: inline-block;
}

.car-booking-right h4 span {
    text-transform: capitalize;
    color: #ec3323;
    font-size: 16px;
}

.price-rent {
    display: inline-block;
    margin-right: 15px;
}

.car-rating {
    display: inline-block;
}

.car-rating ul {
    display: inline-block;
}

.car-rating ul li {
    display: inline-block;
    margin-right: 1px;
    color: #ffcd00;
}

.car-rating p {
    display: inline-block;
    margin-left: 5px;
    color: #001238;
    text-transform: capitalize;
}

.price-rating {
    margin-bottom: 20px;
}

.car-features ul {
    width: 32%;
    float: left;
    margin-top: 20px;
}

.car-features ul li {
    margin: 5px 0;
}

.car-features ul li i {
    margin-right: 5px;
    color: #ec3323;
}

.single-booking h3,
.booking-right h3 {
    font-size: 23px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 28px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0ff
}

.single-booking form label {
    display: block;
    color: #001238;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}

.single-booking form p {
    margin-top: 15px
}

.single-booking form p input {
    width: 100%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    height: 45px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.single-booking form p textarea {
    width: 100%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    height: 120px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.single-booking form p .nice-select {
    width: 100%;
    border: 2px solid #f0f0ff;
    height: 45px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    border-radius: 0;
}

.single-booking form p input:focus {
    border: 2px solid #ec3323
}

.single-booking {
    margin-bottom: 30px;
}

.single-booking:last-child {
    margin-bottom: 0px;
}

.rent-drive-payment .payment {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.rent-drive-payment .payment input[type=radio] {
    position: absolute;
    visibility: hidden;
}

.rent-drive-payment .payment label {
    display: inline-block;
    position: relative;
    padding: 0 0 0 30px;
    margin: 10px auto;
    z-index: 9;
    cursor: pointer;
    transition: all 0.25s linear;
    color: #020202;
    font-weight: 500;
}

.rent-drive-payment .payment .check {
    display: block;
    position: absolute;
    border: 3px solid #AAAAAA;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 14px;
    left: 0;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.rent-drive-payment .payment .check:before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked~.check {
    border: 3px solid #ec3323;
}

input[type="radio"]:checked~.check:before {
    background: #ec3323;
}

input[type="radio"]:checked~label {
    color: #ec3323;
}

.payment img {
    float: right;
    margin-top: 15px;
}

.action-btn {
    text-align: right
}

.action-btn a.rent-drive-btn {
    color: #ec3323;
    margin: 30px 0 0 0
}

.action-btn a.rent-drive-btn:hover {
    color: #fff;
}


/*================================================
25 - GALLERY CSS
==================================================*/

.rent-drive-gallery-area {
    padding-top: 40px;
    padding-bottom: 60px
}

.single-gallery {
    position: relative;
    display: block;
    margin-top: 30px;
    z-index: 6;
}

.single-gallery .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 5;
}

.single-gallery .img-holder img {
    width: 100%;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-transition: all 700ms ease;
    transition: all 700ms ease;
}

.single-gallery .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.78);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 700ms ease 100ms;
    transition: all 700ms ease 100ms;
}

.single-gallery .overlay-content .inner-content {
    display: table;
    width: 100%;
    height: 100%;
}

.single-gallery .overlay-content .inner-content .title-box {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    opacity: 0;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: perspective(1200px) rotateX(-90deg) translateZ(-45px);
    transform: perspective(1200px) rotateX(-90deg) translateZ(-45px);
    -webkit-transition: all 300ms ease 100ms;
    transition: all 300ms ease 100ms;
}

.title-box h3 {
    font-size: 24px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 5px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.single-gallery .overlay-content .inner-content .title-box h3 a {
    color: #ffffff;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.single-gallery .img-holder .link-zoom-button {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    text-align: center;
    display: block;
    background: transparent;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: perspective(1200px) rotateX(90deg) translateZ(-45px);
    transform: perspective(1200px) rotateX(90deg) translateZ(-45px);
    opacity: 0;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 300ms ease 200ms;
    transition: all 300ms ease 200ms;
    z-index: 3;
}

.single-gallery .img-holder .link-zoom-button .single-button {
    position: relative;
    display: block;
    width: 50%;
    float: left;
}

.single-gallery .img-holder .link-zoom-button .single-button a {
    position: relative;
    display: block;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    font-weight: 500;
    background: #ec3323;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.single-gallery .img-holder .link-zoom-button .single-button {
    position: relative;
    display: block;
    width: 50%;
    float: left;
}

.single-gallery .img-holder .link-zoom-button .single-button a.zoom {
    color: #ffffff;
    background: #131313;
}

.single-gallery:hover .img-holder img {
    -webkit-transform: scale(1.2) rotate(2deg);
    transform: scale(1.2) rotate(2deg);
}

.single-gallery:hover .overlay-content {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.single-gallery:hover .overlay-content .inner-content .title-box {
    opacity: 1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-transition: all 700ms ease 500ms;
    transition: all 700ms ease 500ms;
}

.single-button a span {
    margin-right: 5px
}

.single-gallery:hover .img-holder .link-zoom-button {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transition: all 700ms ease 800ms;
    transition: all 700ms ease 800ms;
}


/*================================================
26 - PRODUCT PAGE CSS
==================================================*/

.product_search {
    width: 100%;
    background: #f4f4f5 none repeat scroll 0 0 !important;
    padding: 0 !important;
    height: 50px;
    position: relative;
    border-radius: 5px 0 0 5px;
}

.product_search input {
    position: absolute;
    width: 100%;
    height: 100%;
    border: medium none;
    padding: 5px 15px;
    background: transparent;
}

.product_search button {
    position: absolute;
    right: 0;
    border: medium none;
    height: 100%;
    width: 20%;
    color: #fff;
    cursor: pointer;
    background: #001238 none repeat scroll 0 0;
    font-size: 15px;
    border-radius: 0 5px 5px 0px;
}

.product-page-left .sidebar-widget h3 {
    font-size: 18px
}

.top-products {
    padding: 15px;
    background: #f4f4f5 none repeat scroll 0 0;
}

.top-products li {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    background: #fff none repeat scroll 0 0;
    overflow: hidden
}

.top-products li:last-child {
    border-bottom: 0px solid #ddd
}

.recent-img {
    margin-right: 15px;
    width: 80px;
    float: left;
}

.recent-text {
    vertical-align: middle;
    overflow: hidden;
}

.recent-text h4 {
    font-size: 17px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.recent-text h4 a {
    color: #001238;
}

.recent-text p,
.product-title p {
    color: #ec3323;
    font-weight: 500;
}

.product-image img {
    width: 100%
}

.product-text {
    padding-top: 15px;
}

.product-text h3 {
    font-size: 18px;
    color: #001238;
    letter-spacing: 1px;
    margin: 5px 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.product-text h3 a {
    color: #001238;
}

.product-text {
    padding-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.product-action a {
    width: 45px;
    height: 45px;
    text-align: center;
    border: 1px solid #ddd;
    line-height: 44px;
    font-size: 16px;
    color: #333;
    border-radius: 5px;
}

.product-action a:hover {
    background: #ec3323 none repeat scroll 0 0;
    color: #fff;
    border: 1px solid #ec3323
}

.product-item {
    margin-bottom: 30px;
}


/*================================================
27 - PRODUCT DETAILS PAGE CSS
==================================================*/

.product-details-image {
    text-align: center;
}

.product-details-image img {
    width: 100%;
    padding: 15px;
    background: #f4f4f5 none repeat scroll 0 0;
}

.product-details-text h3 {
    font-size: 30px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 45px;
}

.single-pro-page-para {
    margin: 20px 0;
}

.single-shop-price>p {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    margin-top: 15px;
}

.single-shop-price>p {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    margin-top: 15px;
}

.single-shop-price>p input {
    border: 1px solid #ddd;
    font-weight: 500;
    margin-left: 20px;
    padding: 5px 10px;
    width: 80px;
}

.single-shop-price p span {
    color: #ec3323;
}

.single-shop-page-btn a.rent-drive-btn {
    color: #ec3323;
    margin: 0;
}

.single-shop-page-btn a.rent-drive-btn:hover {
    color: #fff;
}

.single-shop-page-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px;
}

.single-shop-page-btn ul {
    margin-left: 30px;
}

.single-shop-page-btn ul li {
    display: inline-block;
    margin: 0 2px
}

.single-shop-page-btn ul li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #333;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.single-shop-page-btn ul li a:hover,
.rent-drive-cart-close a:hover {
    background: #ec3323 none repeat scroll 0 0;
    color: #fff;
    border: 1px solid #ec3323
}

.rent-drive-related-products .product-item {
    margin-top: 30px;
    margin-bottom: 0
}


/*================================================
28 - CART PAGE CSS
==================================================*/

.cart-table-left h3,
.order-summury-box h3 {
    font-size: 22px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 45px;
}

.cart-table-left thead {
    background: #f4f4f5 none repeat scroll 0 0;
    border: medium none;
}

.cart-table-left thead th {
    border: medium none;
    color: #001238;
}

.rent-drive-cart-preview {
    width: 115px;
    height: 100px;
    text-align: center;
}

.rent-drive-cart-preview img {
    width: 100%;
    height: 100%
}

.table th,
.table td {
    vertical-align: baseline;
    border-top: 0px;
    border-bottom: 1px solid #f0f0ff;
}

.cart-table-left table td p {
    color: #001238;
    text-transform: capitalize;
}

.rent-drive-cart-quantity>input {
    border: 1px solid #ddd;
    padding: 5px;
    width: 70px;
}

.rent-drive-cart-close {
    text-align: center;
}

.rent-drive-cart-total {
    font-weight: 500;
}

.rent-drive-cart-close a {
    color: #333;
    width: 35px;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.cart-clear a {
    display: inline-block;
    font-weight: 500;
    text-transform: uppercase;
    color: #001238;
    margin-right: 15px;
}

.cart-clear {
    margin-top: 20px
}

.cart-clear a:last-child {
    margin-right: 1px;
}

.cart-clear a:hover {
    color: #ec3323
}

.order-summury-box {
    padding: 30px;
    background: #f4f4f5 none repeat scroll 0 0;
}

.order-summury-box table {
    width: 100%;
}

.order-summury-box table tr {
    border-bottom: 1px solid #ddd;
}

.order-summury-box table tr:last-child {
    border-bottom: 0px solid #ddd;
}

.order-summury-box table td {
    padding: 10px 0;
    color: #001238;
}

.checkout-action {
    text-align: right;
    margin-top: 30px
}

.checkout-action a.rent-drive-btn {
    color: #ec3323;
    margin: 0
}

.checkout-action a.rent-drive-btn:hover {
    color: #fff;
}


/*================================================
29 - CHECKOUT PAGE CSS
==================================================*/

.checkout-left-box h3 {
    font-size: 23px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 45px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0ff;
}

.checkout-left-box form .checkout-form {
    margin-top: 15px;
}

.checkout-left-box form input {
    width: 100%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    height: 45px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.checkout-left-box form textarea {
    width: 100%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    height: 135px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.checkout-left-box form input:focus {
    border: 2px solid #ec3323;
}

.checkout-left-box form label {
    color: #001238
}


/*================================================
30 - BLOG DETAILS PAGE CSS
==================================================*/

.single-blog-page .blog-text {
    padding: 30px 0 0 0;
}

.single-blog-page .blog-text p {
    margin: 0 0 10px;
}

.single-blog-page .single-blog {
    border: 0px solid #f0f0ff
}

.blog-content-img {
    margin-bottom: 15px
}

.single-blog-page blockquote {
    margin: 30px 0;
    position: relative;
}

.single-blog-page blockquote .quote-inner {
    border-left: 2px solid #ddd;
    margin-left: 25px;
    padding-left: 60px;
    position: relative;
}

.single-blog-page blockquote .quote-icon {
    background-color: #ffffff;
    border: 2px solid #e9f2f7;
    border-radius: 50%;
    color: #d2dae0;
    font-size: 16px;
    height: 35px;
    left: -18px;
    line-height: 32px;
    margin-top: -17px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 35px;
}

.single-blog-page blockquote .quote-text {
    color: #001238;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
    position: relative;
}

.rent-drive-comment-list,
.rent-drive-leave-comment {
    margin-top: 50px;
}

.rent-drive-comment-list h2,
.rent-drive-leave-comment h2 {
    font-size: 23px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.single-comment-box {
    margin-bottom: 10px;
    position: relative;
    -webkit-transition: all 300ms ease 0s;
    transition: all 300ms ease 0s;
}

.main-comment {
    font-size: 14px;
    padding: 30px 0 0 90px;
    position: relative;
}

.author-image {
    border-radius: 50%;
    left: 0;
    margin-bottom: 20px;
    position: absolute;
    top: 30px;
}

.author-image img {
    display: block;
    width: 70px;
    border-radius: 50%;
    border: 6px solid #e6e6e6;
}

.comment-info h4 {
    font-size: 16px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.comment-info a {
    color: #001238;
    font-style: italic;
}

.comment-info a:hover {
    color: #ec3323
}

.comment-info a i {
    margin-right: 5px
}

.single-comment-box.reply-comment {
    padding-left: 100px;
}

.rent-drive-leave-comment form input {
    width: 45%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    height: 45px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    margin: 10px 15px 10px 0px;
}

.rent-drive-leave-comment form textarea {
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    margin: 10px 0;
    color: #111;
    width: 93%;
    height: 175px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.rent-drive-leave-comment form input:focus,
.rent-drive-leave-comment form textarea:focus {
    border: 2px solid #ec3323
}

.rent-drive-leave-comment .rent-drive-btn-theme {
    width: auto;
    margin-top: 20px
}


/*================================================
31 - NOTFOUND PAGE CSS
==================================================*/

.notfound-box {
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

.notfound-box h2 {
    font-size: 130px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 130px;
}

.notfound-box h3 {
    font-size: 30px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 40px;
}

.notfound-box a.rent-drive-btn {
    margin: 30px 0 0 0;
    color: #ec3323;
}

.notfound-box a.rent-drive-btn:hover {
    color: #fff
}


/*================================================
32 - LOGIN PAGE CSS
==================================================*/

.rent-drive-login-area {
    background: #fbfbfd none repeat scroll 0 0;
}

.login-box {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    width: 40%;
}

.login-page-heading {
    padding-bottom: 20px;
}

.login-page-heading i {
    font-size: 22px;
    margin-right: 5px;
    color: #ec3323;
}

.login-page-heading>h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #001238;
    font-size: 25px;
    text-transform: capitalize;
    margin-bottom: 5px;
    display: inline-block;
}

.login-page-box>form {
    margin-top: 30px;
}

.account-form-group {
    height: 45px;
    margin-bottom: 15px;
    position: relative;
}

.account-form-group>input {
    width: 100%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px 5px 60px;
    height: 45px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.account-form-group i {
    border-right: 2px solid #f0f0ff;
    color: #b7b7b7;
    font-size: 16px;
    height: 100%;
    left: 0;
    line-height: 45px;
    margin-right: 5px;
    padding-right: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 48px;
    -webkit-transition: all .4s ease 0s;
    transition: all .4s ease 0s
}

.login-box button.rent-drive-btn-theme {
    margin: 20px 0 0 0;
    width: auto;
    display: inline-block;
}

.login-page-box button:hover {
    background: #212121 none repeat scroll 0 0
}

.login-sign-up {
    margin-top: 25px;
    padding-top: 10px;
    border-top: 1px solid #f0f0ff;
}

.login-sign-up>a {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    color: #001238;
}

.login-sign-up>a:hover {
    color: #ec3323
}

.login-page-box .remember {
    margin-left: 0 !important;
}

.remember-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.login-meta p {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.remember-row .checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.remember-row .checkbox.signup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.lost-pass a {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    color: #001238;
    margin-right: 15px;
}

.lost-pass a:hover {
    color: #ec3323;
}

.checkbox label {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    color: #001238;
}

@-webkit-keyframes pull {
    0% {
        height: 0;
    }

    100% {
        height: 1.2rem;
    }
}

@keyframes pull {
    0% {
        height: 0;
    }

    100% {
        height: 1.2rem;
    }
}

@-webkit-keyframes pop {
    0% {
        opacity: 0;
        filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    75% {
        opacity: 1;
        filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@keyframes pop {
    0% {
        opacity: 0;
        filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    75% {
        opacity: 1;
        filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.checkbox-spin {
    display: none;
}

.checkbox-spin+label span {
    display: inline-block;
    width: 25px;
    height: 16px;
    margin: 0 10px -3px 0;
}

.checkbox-spin+label span:before,
.checkbox-spin+label span:after {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "";
    position: absolute;
    z-index: 1;
    width: 16px;
    width: 16px;
    width: 16px;
    width: 1rem;
    height: 16px;
    height: 16px;
    height: 16px;
    height: 1rem;
    background: #fff none repeat scroll 0 0;
    border: 2px solid #dbdbdb;
}

.checkbox-spin+label span:after {
    z-index: 0;
    border: none;
}

.checkbox-spin:checked+label span:before {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
    background: #ec3323;
    border: 1px solid #ec3323;
}

.whatsapp-sticky {
    position: fixed;
    right: 20px;
    bottom: 100px;
    color: #f7f7f7;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    z-index: 999;
    background: #0fb065;
    border-radius: 50%;
    opacity: .8;
   
  }
  .whatsapp-sticky.left {
    left: 20px !important;
    bottom: 70px !important;
  }
  .whatsapp-sticky:hover{opacity: 1;color: #fff;}
  
  .highlights-offers ul li{
    position: relative;
    margin-top: 15px;
  }

/*================================================
33 - CONTACT PAGE CSS
==================================================*/

.contact-left h3,
.contact-right h3 {
    font-size: 24px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.contact-left form input {
    width: 100%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    height: 45px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    margin: 10px 15px 10px 0px;
}

.contact-left form textarea {
    border: 2px solid #f0f0ff;
    padding: 5px 10px;
    margin: 10px 0;
    color: #111;
    width: 100%;
    height: 135px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.contact-left form input:focus,
.contact-left form textarea:focus {
    border: 2px solid #ec3323;
}

.single-contact-field button.rent-drive-btn-theme {
    width: auto;
    display: inline-block;
    margin-top: 20px
}

.contact-details p i {
    color: #ec3323;
    margin-right: 5px;
}

.single-contact-btn {
    display: inline-block;
    margin-right: 10px;
    margin-top: 25px;
}

.single-contact-btn h4,
.social-links-contact h4 {
    font-size: 16px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.single-contact-btn a {
    display: inline-block;
    padding: 10px 16px;
    text-transform: lowercase;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 500;
    background: #ec3323 none repeat scroll 0 0;
    border-radius: 4px;
}

.single-contact-btn a:hover {
    background: #001238 none repeat scroll 0 0
}

.social-links-contact {
    margin-top: 30px;
}

.social-links-contact ul li {
    display: inline-block;
    margin: 0 2px;
}

.social-links-contact ul li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #333;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.social-links-contact ul li a:hover {
    background: #ec3323 none repeat scroll 0 0;
    color: #fff;
    border: 1px solid #ec3323;
}
.shadow-none:focus{
    box-shadow: none !important;
    outline: none;
}
/* tab responsive */
@media only screen and (max-width: 992px)  {

    .navbar-toggler .icon-bar {
        background-color: red;
        display: block;
        height: 3px;
        width: 26px;
        transition: all 0.2s;
        margin-bottom: 4px;
      }
      
      .navbar-toggler .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
      
      .navbar-toggler.collapsed .top-bar {
        transform: rotate(0);
      }
      
      .navbar-toggler.collapsed .middle-bar {
        opacity: 1;
      }
      
      .navbar-toggler.collapsed .bottom-bar {
        transform: rotate(0);
      }
      
      .navbar-toggler .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      
      .navbar-toggler .middle-bar {
        opacity: 0;
      }
     
      .navbar-toggler.collapsed  .icon-bar,  .menu-icon {
        opacity: 0;
        position: absolute;
        right: 10px;
        top: -5px;
        transition: all .3s;;
      }
      .navbar-toggler.collapsed .menu-icon{
        opacity: 1;
        height: auto;
      }
    .navbar .dropdown:hover>ul {
        opacity: 1;
        display: block;
        visibility: visible;
      }
      .navbar-toggler, .navbar-toggler:focus, .navbar-toggler:active{
        border: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
      }
}
@media only screen and (max-width: 480px)  {
    .single-offers .car-detail-list{
        height: auto;
    }
    .login-boxs {
        width: 100%;
        padding: 30px 15px;
    }

    .header-top-left {
        background: #ec3323 none repeat scroll 0 0;
        position: relative;
        /* z-index: -1; */
        padding: 15px 0;
        height: 107%;
        width: 107%;
        margin-left: -12px;
        text-align: center;
    }

    .header-top-right {
        text-align: center;
        padding: 10px 0;

    }

    .header-top-left:before {
       display:none;
    }

   

      .header-top-right>a {
        color: #eee;
        text-transform: capitalize;
        text-align: center;
    }


    .imageload {
        background-color: white;
        border-radius: 50%;
        margin-left: 57px;
        height: 125px;
        width: 125px;
        margin-top: 22px;
    }
    .header-top-left{
        background-color: transparent;
    }
    .logo-text{display: none;}
    
}


