 
.bottonsuccess{

    background-color:#3c5f3c;
    padding-left:5px;
    padding-right:5px;
    padding-bottom:3px;
    border-radius: 3px;
    color:white;
    font-size:12px;
    cursor: pointer;    
        
    }
    
    .bottoninfo{
    
        background-color:#398aaa;
        padding-left:5px;
        padding-right:5px;
        padding-bottom:3px;
        border-radius: 3px;
        color:white;
        font-size:12px;
        cursor: pointer;    
            
        }
    
        .bottondanger{
    
            background-color:#a13514;
            padding-left:5px;
            padding-right:5px;
            padding-bottom:3px;
            border-radius: 3px;
            color:white;
            font-size:12px;
            cursor: pointer;    
                
            }


            .modal {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                display: none;
                justify-content: center;
                align-items: center;
              }
              
              .modal-content {
                background-color: #fff;
                padding: 20px;
                border-radius: 4px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              }
              
              .modal-content h3 {
                margin-top: 0;
              }
              
              .modal-content p {
                margin-bottom: 20px;
              }
              
              .modal-content button {
                margin-right: 10px;
              }


              