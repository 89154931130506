/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
    padding: 0 100px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li+li {
    margin-top: 15px;
  }
  
  .faq .faq-list li {
   
    position: relative;
  }
  
  
  
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #47b2e4;
  }
  
  .faq .faq-list .icon-show, .faq .faq-list .icon-close {
    font-size: 26px;
    position: absolute;
    right: 5px;
    top: 20px;
    background: #646464;
    color: #fff;
    padding: 0px;
    border-radius: 50%;
    height: 17px;
    width: 17px;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list a.collapsed, .faq .faq-list a.collapse {
    color: #282828;
    transition: 0.3s;
    background: #fff;
    
  }
  .faq .faq-list li a {
    display: block;
    position: relative;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 15px;
    outline: none;
    cursor: pointer;
    width: 100%;
    background: #ececec !important;
  }
  .faq .faq-list a.collapsed:hover {
    color: #0d0d0d;
  }
  
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  .faq .faq-list .faq-body {
    background: #f9f9f9a6;
    padding: 20px;
  }
  .faq .faq-list .faq-body .topic{
    font-size: 18px;
    margin-left: 10px;
  }
  .faq .faq-list .list-under-faq{
    list-style-type: disc;
    padding-left: 30px;
    margin-bottom: 15px;
  }
  .faq .faq-list .list-under-faq li{
    list-style-type: disc;
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 10px;
    background: transparent;
  }
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }