/* ===================== HOT OFFERS ======================*/
#offerTab {
    text-align: center;
    width: 100%;
    display: block;
    border: medium none;
    margin: 30px 0;
}

#offerTab li {
    display: inline-block;
    margin: 0 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border: medium none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: medium none;
}

.nav-tabs .nav-link {
    border: medium none;
}

#offerTab li.nav-item .nav-link,
#offerTab li.nav-item .nav-link.active {
    display: inline-block;
    text-transform: uppercase;
    padding: 7px 25px;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    position: relative;
    z-index: 1;
}

#offerTab li.nav-item .nav-link:after {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: #020202 none repeat scroll 0 0;
    z-index: -1;
    -webkit-transform: skew(40deg);
    transform: skew(40deg);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

#offerTab li.nav-item .nav-link:hover:after {
    background: #ec3323 none repeat scroll 0 0;
    -webkit-transform: skew(-40deg);
    transform: skew(-40deg);
}

#offerTab .nav-tabs {
    width: 100%;
    display: block;
    border: medium none;
    margin: 30px 0;
    justify-content: center;
}

#offerTab li.nav-item .nav-link.active:after {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: #ec3323 none repeat scroll 0 0;
    -webkit-transform: skew(-40deg);
    transform: skew(-40deg);
}

.single-offers {
    text-align: center;
    padding: 10px;
    border: 3px solid #f0f0ff;
    margin-top: 30px;
}

.offer-image {
    width: 100% !important;
    margin: 0 auto;
    display: block;
    padding: 0;
    height: 160px;
}

.offer-image img {
    width: 100% !important;
    
}

.offer-text {
    margin-top: 20px;
}

.offer-text h5 {
    font-size: 18px;
    color: #001238;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.offer-text h4 {
    color: #020202;
    font-size: 18px;
    text-transform: capitalize;
    margin-top: 8px;
}

.offer-text h4 span {
    text-transform: capitalize;
    color: #ec3323;
    font-size: 16px;
}

.offer-action {
    text-align: center;
    background: #020202 none repeat scroll 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    margin: 25px auto 0;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.offer-action a {
    display: inline-block;
    text-transform: uppercase;
    padding: 7px 15px;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    width: 47%;
}

.offer-action:after {
    position: absolute;
    content: "";
    width: 66%;
    height: 100%;
    background: #ec3323 none repeat scroll 0 0;
    right: -36px;
    z-index: -1;
    -webkit-transform: skewX(40deg);
    transform: skewX(40deg);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.offer-action:hover {
    background: #ec3323 none repeat scroll 0 0;
}

.offer-action:hover:after {
    background: #020202 none repeat scroll 0 0;
}

.offer-text ul {
    text-align: center;
    margin-top: 10px;
}

.offer-text ul li {
    display: block;
    font-size: 14px;
    margin: 15px;
}

.offer-text ul li svg {
    margin: auto;
    fill: #555;
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
}
.offer-text ul li img {
    width: 50px;
    height: 50px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .offer-tabs .row .col-lg-4:last-child {
        display: none;
    }

    .offer-tabs .row .col-lg-4,
    .rent-drive-blog-area .row .col-lg-4 {
        width: 50%;
    }

    .offer-action:after {
        width: 62%;
    }
}

@media (max-width: 767px) {
    #offerTab li {
        display: block;
        margin-bottom: 5px;
    }

    #offerTab li a {
        display: block !important;
    }

    #offerTab li.nav-item .nav-link.active:after,
    #offerTab li.nav-item .nav-link:after {
        -webkit-transform: skewX(0);
        transform: skewX(0);
    }

    #offerTab li.nav-item .nav-link:hover:after {
        -webkit-transform: skewX(0);
        transform: skewX(0);
    }

    .single-offers {
        padding: 10px;
    }

 
    #offerTab li.nav-item .nav-link {
        display: block;
        width: 100%;
    }
    .offer-image{
        height: auto;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .offer-action:after {
        width: 62%;
    }

    .single-offers {
        padding: 15px;
    }

    .offer-text ul li {
        margin: 0 3px;
    }

    .offer-text ul li i {
        margin-right: 3px;
    }

    #offerTab li {
        display: block;
        margin-bottom: 5px;
    }

    #offerTab li a {
        display: block !important;
    }

    #offerTab li.nav-item .nav-link.active:after,
    #offerTab li.nav-item .nav-link:after {
        -webkit-transform: skewX(0);
        transform: skewX(0);
    }

    #offerTab li.nav-item .nav-link:hover:after {
        -webkit-transform: skewX(0);
        transform: skewX(0);
    }
}

.car-img{

    width:100px !important;
}