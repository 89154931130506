*{
    text-decoration: none !important;
    }
    
    
    /* Adjust the width value as needed */
    .react-datepicker-wrapper {
      display: block !important;
    }
    
    
    
    .city{
        border: 1px solid gray !important;
        border-radius: 2px !important;
        height: 2em !important;
        padding: 0 0.5em !important;
    }
    
    .mbsc-page{
        margin-top:-16px;
        height:47px;
    }
    
    .mbsc-windows.mbsc-textfield-wrapper {
         margin: 0em !important; 
         margin-top:20px !important;
    }

    
    .rangecontainer{
        display:none !important;
    }
    
    .rangedate{
        text-transform: uppercase;
        margin-top:20px;
    }
    

    .car-list-page{

        margin-top:0px !important;
    }

    .bgs{
        margin-top:-75px !important;
        display:none;
    }
    

    .buttons {
        display: inline-block;
        text-align: center;
        padding: 10px 25px 10px 0px;
        background: #ec3323 none repeat scroll 0 0;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        z-index: 1;
        left: 25px;
    }

 
.buttons:before {
    position: absolute;
    background: #ec3323 none repeat scroll 0 0;
    content: "";
    top: 0;
    left: -25px;
    width: 35%;
    height: 100%;
    transform: skewX(45deg);
    z-index: -1;
}
    

.welcome{

    margin-top: -17px;
}

.rent-drive-themes-btn{
        color: #ec3323;
        background: white;
        border-width: 2px;
        border-style: solid;
        border-color: #ec3323;
        position: relative;
        display: block;
        padding: 8px 15px;
        transition: all 0.3s ease-in-out;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        margin: 0;
        width: 100%;
   
}
.logo-top {
    max-width: 267px;
}
@media only screen and (max-width: 767px) {
    .logo-top {
        max-width:100% !important;
    }
}
