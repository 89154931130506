.img-css{
    width:50%;
}

.box-css{
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    background: #e8e8e8;
}

.rent-drive-page{
    margin-top:0;
    margin-bottom:100px;
    background: #ececec;
}
