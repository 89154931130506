.rent-drive-login-area {
    background: #fbfbfd none repeat scroll 0 0;
}



.login-page-heading {
    padding-bottom: 20px;
}

.login-page-heading svg {
    font-size: 22px;
    margin-right: 5px;
    color: #ec3323;
}

.login-page-heading>h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #001238;
    font-size: 25px;
    text-transform: capitalize;
    margin-bottom: 5px;
    display: inline-block;
}

.login-page-box>form {
    margin-top: 30px;
}

.account-form-group {
    height: 45px;
    margin-bottom: 15px;
    position: relative;
}

.account-form-group>input {
    width: 100%;
    border: 2px solid #f0f0ff;
    padding: 5px 10px 5px 60px;
    height: 45px;
    color: #111;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.account-form-group svg {
    border-right: 2px solid #f0f0ff;
    color: #b7b7b7;
    font-size: 16px;
    height: 100%;
    left: 0;
    line-height: 45px;
    margin-right: 5px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 48px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    padding: 15px;
}

.login-boxs button.rent-drive-theme-btn {
    margin: 20px 0 0 0;
    width: auto;
    display: inline-block;
}

.login-page-box button:hover {
    background: #212121 none repeat scroll 0 0;
}

.login-sign-up {
    margin-top: 25px;
    padding-top: 10px;
    border-top: 1px solid #f0f0ff;
}

.login-sign-up>a {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    color: #001238;
}

.login-sign-up>a:hover {
    color: #ec3323;
}

.login-page-box .remember {
    margin-left: 0 !important;
}

.remember-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.login-meta p {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.remember-row .checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.remember-row .checkbox.signup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.lost-pass a {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    color: #001238;
    margin-right: 15px;
}

.lost-pass a:hover {
    color: #ec3323;
}

.checkbox label {
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    color: #001238;
}

@-webkit-keyframes pull {
    0% {
        height: 0;
    }

    100% {
        height: 1.2rem;
    }
}

@keyframes pull {
    0% {
        height: 0;
    }

    100% {
        height: 1.2rem;
    }
}

@-webkit-keyframes pop {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    75% {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@keyframes pop {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    75% {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.checkbox-spin {
    display: none;
}

.checkbox-spin+label span {
    display: inline-block;
    width: 25px;
    height: 16px;
    margin: 0 10px -3px 0;
}

.checkbox-spin+label span:before,
.checkbox-spin+label span:after {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "";
    position: absolute;
    z-index: 1;
    width: 16px;
    width: 16px;
    width: 16px;
    width: 1rem;
    height: 16px;
    height: 16px;
    height: 16px;
    height: 1rem;
    background: #fff none repeat scroll 0 0;
    border: 2px solid #dbdbdb;
}

.checkbox-spin+label span:after {
    z-index: 0;
    border: none;
}

.checkbox-spin:checked+label span:before {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
    background: #ec3323;
    border: 1px solid #ec3323;
}

.login-boxs {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    width: 35%;
  }



